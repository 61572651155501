<template>
  <Datepicker
    v-model="datePicker"
    class="mb-1"
    style="width:100%"
    format="DD/MM/YYYY HH:mm:ss"
    lang="th"
    type="datetime"
  />
</template>
<script>
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { defineComponent } from '@vue/composition-api'
import { BFormDatepicker } from 'bootstrap-vue'
import moment from 'moment'

export default defineComponent({
  name: 'date_picker',
  components: {
    BFormDatepicker,
    Datepicker
  },
  props: ['value'],
  data () {
    return {
      datePicker: moment(this.value).toDate()
    }
  },
  watch: {
    datePicker (event) {
      this.$emit('input', moment(event))
    }
  },
  methods: {
    setdate (val) {
      this.datePicker = new Date(val)
    }
  },
  computed: {
    statusReadOnly () {
      return this.status === '' ? false : this.status
    }
  }
})
</script>
