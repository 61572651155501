<template>
  <div class="container-fluid">
    <div class="card">
      <b-tabs>
        <b-tab
          title="กิจกรรม"
          title-item-class="card-title"
        >
          <div class="row justify-content-between my-1 mx-1">
            <div class="col-md-6 col-xl-2 mb-1">
              <b-button
                v-b-modal.add-event-modal
                block
                variant="success"
                style="height: 38px;"
              >
                เพิ่มกิจกรรม
              </b-button>
            </div>
            <div class="col-md-6 col-xl-3  mb-1">
              <b-form-group style="margin-bottom: 0rem;">
                <div class="d-flex align-items-center">
                  <label class="mr-1">ค้นหา</label>
                  <b-form-input
                    v-model="filter"
                    placeholder="ค้นหา"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>
          </div>
          <b-table
            striped
            hover
            responsive
            class="table position-relative"
            show-empty
            :items="this.$store.state.event.listEvent.filter(f => moment(f.end).isAfter(moment()))"
            :fields="fields"
            :filter="filter"
          >
            <template #cell(name)="data">
              {{ data.item.name }}
              <b-badge
                v-if="checkNotify(data.item) !== ''"
                pill
                class=""
                style="margin-right: 5px"
                variant="light-danger"
              >
                {{ checkNotify(data.item) }}
              </b-badge>
              <b-badge
                v-if="checkNotify100(data.item) !== ''"
                pill
                class=""
                variant="light-info"
              >
                {{ checkNotify100(data.item) }}
              </b-badge>
            </template>
            <template #cell(target)="data">
              {{ data.item.target | toCurrencyBath }}
            </template>
            <template #cell(start)="data">
              {{ data.item.start | formatDateSort }}
            </template>
            <template #cell(end)="data">
              {{ data.item.end | formatDateSort }}
            </template>
            <template #cell(action)="data">
              <div class="d-flex align-items-center">
                <b-button
                  variant="primary mr-1"
                  @click="navigateToReport(data.item)"
                >
                  รายงาน
                </b-button>
                <b-button
                  variant="danger"
                  @click="deleteEvent(data.item)"
                >
                  ลบ
                </b-button>
              </div>
            </template>
            <template #empty>
              <div class="col-md-12 text-center m-1">
                <h4>ไม่พบข้อมูลสินค้า</h4>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="col-md-12 text-center m-1">
                <h4>ไม่พบข้อมูลสินค้า</h4>
              </div>
            </template>
          </b-table>
        </b-tab>
        <b-tab
          title="ประวัติ"
          title-item-class="card-title"
        >
          <div class="row justify-content-end my-1 mx-1">
            <div class="col-md-6 col-xl-3  mb-1">
              <b-form-group style="margin-bottom: 0rem;">
                <div class="d-flex align-items-center">
                  <label class="mr-1">ค้นหา</label>
                  <b-form-input
                    v-model="filter"
                    placeholder="ค้นหา"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>
          </div>
          <b-table
            striped
            hover
            responsive
            class="table position-relative"
            show-empty
            :items="this.$store.state.event.listEvent.filter(f => !moment(f.end).isAfter(moment()))"
            :fields="fields"
            :filter="filter"
          >
            <template #cell(name)="data">
              {{ data.item.name }}
              <b-badge
                v-if="checkNotify100History(data.item) !== ''"
                pill
                class=""
                variant="light-info"
              >
                {{ checkNotify100History(data.item) }}
              </b-badge>
            </template>
            <template #cell(target)="data">
              {{ data.item.target | toCurrencyBath }}
            </template>
            <template #cell(start)="data">
              {{ data.item.start | formatDateSort }}
            </template>
            <template #cell(end)="data">
              {{ data.item.end | formatDateSort }}
            </template>
            <template #cell(action)="data">
              <div class="d-flex align-items-center">
                <b-button
                  variant="primary mr-1"
                  @click="navigateToReport(data.item)"
                >
                  รายงาน
                </b-button>
                <b-button
                  variant="danger"
                  @click="deleteEvent(data.item)"
                >
                  ลบ
                </b-button>
              </div>
            </template>
            <template #empty>
              <div class="col-md-12 text-center m-1">
                <h4>ไม่พบข้อมูลสินค้า</h4>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="col-md-12 text-center m-1">
                <h4>ไม่พบข้อมูลสินค้า</h4>
              </div>
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal
      id="add-event-modal"
      title="เพิ่มกิจกรรม"
      size="lg"
    >
      <validation-observer ref="addEventForm">
        <validation-provider
          #default="{errors}"
          rules="required"
        >
          <b-form-group>
            <label for="name">ชื่อกิจกรรม</label>
            <b-form-input
              v-model="add.name"
              placeholder="ชื่อกิจกรรม"
              type="text"
              class="d-inline-block"
              :state="errors.length > 0 ? false:null"
            />
            <small
              v-if="errors.length > 0"
              class="text-danger"
            >กรุณากรอกชื่อกิจกรรม</small>
          </b-form-group>
        </validation-provider>
        <div class="mt-1" />
        <validation-provider
          #default="{errors}"
          rules="required|min_value:1"
        >
          <b-form-group>
            <label for="target">ยอดเป้าหมาย</label>
            <b-form-input
              id="target"
              v-model="add.target"
              placeholder="บาท"
              type="number"
              class="d-inline-block"
              :state="errors.length > 0 ? false:null"
            />
            <small
              v-if="errors.length > 0"
              class="text-danger"
            >กรุณากรอกยอดเป้าหมาย</small>
          </b-form-group>
        </validation-provider>
        <div class="row mt-1">
          <div class="col-6">
            <b-form-group>
              <label for="start">เริ่มต้น</label>
              <DateTimePicker
                id="start"
                v-model="add.start"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group>
              <label for="end">สิ้นสุด</label>
              <DateTimePicker
                id="end"
                v-model="add.end"
              />
            </b-form-group>
          </div>
        </div>
      </validation-observer>
      <template #modal-footer="{close}">
        <b-button
          variant="success"
          @click="addEvent(close)"
        >
          เพิ่ม
        </b-button>
        <b-button
          variant="secondary"
          @click="close"
        >
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { BButton, BFormInput, BTable, BModal, BFormGroup, BTab, BTabs, BBadge } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from '@validations'
import {
  min_value
} from 'vee-validate/dist/rules'
import DateTimePicker from '@/views/components/production/dateTimePicker.vue'
import router from '@/router'

export default {
  components: {
    BTable,
    BButton,
    BFormInput,
    BFormGroup,
    BModal,
    BTab,
    BTabs,
    BBadge,
    DateTimePicker,
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    add: {
      name: '',
      target: undefined,
      start: moment().startOf('d'),
      end: moment().add(3, 'month').startOf('d')
    },
    filter: '',
    fields: [
      {
        key: 'name',
        label: 'ชื่อกิจกรรม'
      },
      {
        key: 'target',
        label: 'ยอดเป้าหมาย',
        tdClass: 'text-right'
      },
      {
        key: 'start',
        label: 'วันที่เริ่ม'
      },
      {
        key: 'end',
        label: 'วันที่สิ้นสุด'
      },
      {
        key: 'action',
        label: ''
      }
    ],
    required,
    moment
  }),
  watch: {
  },
  beforeCreate: () => {
    extend('min_value', min_value)
  },
  created () {
    this.$store.dispatch('event/getEvent')
  },
  methods: {
    async addEvent (close) {
      if (await this.$refs.addEventForm.validate()) {
        this.$store.dispatch('event/addEvent', this.add).then(result => {
          this.add = {
            name: '',
            target: undefined,
            start: moment(),
            end: moment().add(3, 'month')
          }
        }).catch(error => {
          console.log(error)
        }).finally(() => close())
      }
    },
    deleteEvent (item) {
      this.$store.dispatch('event/deleteEvent', { id: item._id }).then(result => {
        console.log(result)
      }).catch(error => {
        console.log(error)
      })
    },
    navigateToReport (item) {
      router.push(`/event/${item._id}`)
    },
    checkNotify (item) {
      const finded = this.$store.state.event.progressEventList.find(i => i.event._id === item._id)
      if (finded !== undefined) {
        const count = finded.progress.reduce((acc, cur) => {
          if (cur.percent >= 80 && cur.percent < 100) {
            return acc + 1
          }
          return acc
        }, 0)
        return count > 0 ? count : ''
      }
      return ''
    },
    checkNotify100 (item) {
      const finded = this.$store.state.event.progressEventList.find(i => i.event._id === item._id)
      if (finded !== undefined) {
        const count = finded.progress.reduce((acc, cur) => {
          if (cur.percent >= 100 && !finded.event.claimRewards.some(c => c.id === cur.customer._id)) {
            return acc + 1
          }
          return acc
        }, 0)
        return count > 0 ? count : ''
      }
      return ''
    },
    checkNotify100History (item) {
      console.log(item)
      if (item.progress !== undefined) {
        const count = item.progress.reduce((acc, cur) => {
          if (cur.percent >= 100 && !item.claimRewards.some(c => c.id === cur.customer._id)) {
            return acc + 1
          }
          return acc
        }, 0)
        return count > 0 ? count : ''
      }
      return ''
    }
  }
}
</script>

<style>

</style>
